<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div class="app-popup SharePopup" v-show="show">
      <transition enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
        <div class="box" v-if="show">
          <div class="box-title">
            <img src="@/assets/image/hardware/sharePopup1.gif" alt="">
            <!-- <p>請點擊下方「分享螢幕畫面」按鈕，</p>
            <p>並且選擇分享「整個螢幕畫面」進行考試。</p> -->
          </div>
          <div class="box-button">
            <button v-loading="shareStatus" @click="shareClick">
              <img src="@/assets/image/icon/icon-share.png" alt="分享">
              分享螢幕畫面
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "SharePopup",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  watch: {
    // 監聽屏幕分享 關閉彈窗
    shareType(val) {
      if(val == 1) {
        this.show = false
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    ...mapGetters(['shareType', 'shareStatus'])
  },
  methods: {
    // 分享螢幕畫面
    shareClick() {
      this.$store.dispatch('agora/openScreenShare')
    }
  }
}
</script>

<style lang="less" scoped>
.SharePopup {
  z-index: 999 !important;
  .box {
    width: 870px;
    height: 648px;
    background-image: url('../assets/image/hardware/sharePopup.png');
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    .box-title {
      position: absolute;
      top: 65px;
      left: 55px;
      width: calc(100% - 110px);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        height: auto;
      }
      // font-family: "Microsoft JhengHei","微軟正黑體";
      // font-style: normal;
      // font-weight: 700;
      // font-size: 26px;
      // line-height: 50px;
      // text-align: center;
      // color: #666666;
    }
    .box-button {
      position: absolute;
      left: 0;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        cursor: pointer;
        width: 438px;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px 1px rgba(32, 80, 228, 0.6), inset 0px 0px 10px 5px rgba(3, 74, 255, 0.3);
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: #0C4FA2;
        border: none;
        overflow: hidden;
        img {
          margin-right: 10px;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
</style>