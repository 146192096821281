<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div class="ProblemReturns-popup" v-show="problemStatus != 0">
      <transition
        enter-active-class="animated fadeInRightBig"
        leave-active-class="animated fadeOutRightBig"
      >
        <div class="box" v-if="problemStatus != 0">
          <div class="box-content">
            <button class="box-content-close" @click="close">
              <img src="@/assets/image/header/problemReturns-close.png" alt="">
            </button>
            <div class="box-content-text">
              <template v-if="problemStatus == 1">
                <img class="box-content-text-img" src="@/assets/image/icon/icon-returns.png" alt="">
                <h1 class="box-content-text-h1">取消問題回報</h1>
              </template>
              <template v-if="problemStatus == 2">
                <img class="box-content-text-img" src="@/assets/image/icon/icon-returnss.png" alt="">
                <h1 class="box-content-text-h1" v-if="problemResultsStatus == 1">已完成處理</h1>
                <h1 class="box-content-text-h1" v-if="problemResultsStatus == 2">無法處理問題但已註記問題狀況</h1>
                <h1 class="box-content-text-h1" v-if="problemResultsStatus == 3">無法成功聯繫學生</h1>
              </template>
              <div class="box-content-text-div" v-if="problemStatus == 2">
                {{ problemResults }}
              </div>
              <div class="box-content-text-p">
                <p>時間恢復計算</p>
                <p>請繼續作答</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
export default { 
  name: 'ProblemReturns',
  computed: {
    ...mapGetters(['problemStatus', 'problemResultsStatus', 'problemResults'])
  },
  data() {
    return {
      // 關閉5秒倒計時 實際時間+1
      countDown: 6,
    }
  },
  methods: {
    // 關閉彈窗
    close() {
      this.$store.commit('problem/SET_PROBLEMSTATUS', 0)
    }
  }
}
</script>

<style lang="less" scoped>
.ProblemReturns-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  .box {
    width: 100%;
    height: 100%;
    position: relative;
    .box-content {
      position: absolute;
      top: 70px;
      right: 56px;
      width: 230px;
      min-height: 660px;
      height: calc(100% - 140px);
      border-radius: 8px;
      background-image: url('../assets/image/header/problemReturns.png');
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-items: center;
      .box-content-close {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 0;
        background: none;
        border: none;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-content-text {
        padding: 0 28px;
        width: calc(100% - 56px);
        text-align: center;
        .box-content-text-img {
          width: 60px;
          height: 60px;
        }
        .box-content-text-h1 {
          margin: 24px 0;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #FFFFFF;
        }
        .box-content-text-div {
          margin: 100px 0;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          color: #FFFFFF;
          white-space: pre-line;
          word-break: break-all;
        }
        .box-content-text-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>