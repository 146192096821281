<template>
  <div class="NavFooter">
    <div class="box">
      <div class="box-left">
        <img src="@/assets/image/footer/footer.png" alt="footer">
      </div>
      <div class="box-right">
        <div class="box-right-mail">
          <img src="@/assets/image/footer/mail.png" alt="郵箱">
          Email: jept@joy.com.tw
        </div>
        <div class="box-right-version">
          COPYRIGHT© 2023 JOY EDUCATIONAL FOUNDATION. ALL RIGHTS RESERVED.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavFooter"
}
</script>

<style lang="less" scoped>
.NavFooter {
  width: 100%;
  background: #032373;
  .box {
    margin: 0 auto;
    height: 60px;
    width: 1320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: 206px;
      height: 46px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-right {
      display: flex;
      align-items: center;
      .box-right-mail {
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: rgba(255, 255, 255, 0.5);
        img {
          margin-right: 8px;
          width: 16px;
          height: 16px;
        }
      }
      .box-right-version {
        margin-left: 66px;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

// @media screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  
}
@media screen and (max-width: 1024px) {
  
}
@media screen and (max-width: 800px) {
  
}
// @media screen and (max-width:750px) {}
</style>