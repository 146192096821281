
const getters = {
  shareType: state => state.agora.shareType,
  shareStatus: state => state.agora.shareStatus,
  agoraAppid: state => state.agora.agoraAppid,
  agoraChannel: state => state.agora.agoraChannel,
  screenTrack: state => state.agora.screenTrack,
  agoraUid: state => state.agora.agoraUid,
  agoraCameraToken: state => state.agora.agoraCameraToken,
  agoraScreenToken: state => state.agora.agoraScreenToken,
  problemStatus: state => state.problem.problemStatus,
  problemResultsStatus: state => state.problem.problemResultsStatus,
  problemResults: state => state.problem.problemResults,
  problemPopupStatus: state => state.problem.problemPopupStatus,
  admissionTicket: state => state.user.admissionTicket,
  entryLevel: state => state.user.entryLevel,
  examCountdown: state => state.exam.examCountdown,
  examType: state => state.exam.examType,
  examStatus: state => state.exam.examStatus,
  examAuestionSort: state => state.exam.examAuestionSort,
  examRemainingTime: state => state.exam.examRemainingTime,
  examRestTimeStatus: state => state.exam.examRestTimeStatus,
  examIsLast: state => state.exam.examIsLast,
  examinationRoomType: state => state.exam.examinationRoomType,
  examOverStatus: state => state.exam.examOverStatus,
}
export default getters