import AgoraRTC from "agora-rtc-sdk-ng"
import Store from "@/store"
// import { agroaRecordingStart, agroaRecordingStop } from '@/request/api'
const state = {
  // 屏幕分享狀態  0未開啟  1正在分享  2停止分享
  shareType: 0,
  // 分享处理中状态
  shareStatus: false,
  // agora appid
  agoraAppid: process.env.VUE_APP_AGORAID,
  // 房间号(房间号和token对应)
  agoraChannel: sessionStorage.getItem('agoraChannel'),
  // agora 用户uid
  agoraUid: sessionStorage.getItem('agoraUid'),
  // agora 摄像头token(房间号和token对应)
  agoraCameraToken: '',
  // agora 屏幕分享token(房间号和token对应)
  agoraScreenToken: '',
  // 屏幕分享实例
  screenClient: null,
  // 屏幕分享本地
  screenTrack: null,
  // 视频实例
  videoClient: null,
  // 开/关云端录制所需参数
  agoraResourceId: '',
  agoraSid: '',
}

const mutations = {
  // 屏幕分享实例
  SET_SCREENCLIENT: (state, screenClient) => {
    state.screenClient = screenClient
  },
  // 屏幕分享本地实例
  SET_SCREENTRACK: (state, screenTrack) => {
    state.screenTrack = screenTrack
  },
  // 视频分享实例
  SET_VIDEOCLIENT: (state, videoClient) => {
    state.videoClient = videoClient
  },
  // 分享状态
  SET_SHARETYPE(store, shareType) {
    store.shareType = shareType
  },
  // 处理状态
  SET_SHARESTATUS(store, shareStatus) {
    store.shareStatus = shareStatus
  },
  // 用户uid
  SET_AGORAUID: (state, agoraUid) => {
    state.agoraUid = agoraUid
  },
  // 房间号
  SET_AGORACHANNEL: (state, agoraChannel) => {
    state.agoraChannel = agoraChannel
  },
  // 摄像头token
  SET_AGORACAMERATOKEN(store, agoraCameraToken) {
    store.agoraCameraToken = agoraCameraToken
  },
  // 屏幕分享token
  SET_AGORASCREENTOKEN(store, agoraScreenToken) {
    store.agoraScreenToken = agoraScreenToken
  },
  // 关闭agora云端录制
  SET_AGORARESOURCEID(store, agoraResourceId) {
    store.agoraResourceId = agoraResourceId
  },
  // 关闭agora云端录制
  SET_AGORASID(store, agoraSid) {
    store.agoraSid = agoraSid
  },
}

const actions = {
    // 分享熒幕
    openScreenShare({ dispatch, commit }) {
      // 考場類型 3練習模擬考不進行推流 1正式 2仿真模擬考都進行推流
      const examinationRoomType = Store.state.exam.examinationRoomType
      commit('SET_SHARESTATUS', true)
      const { agoraAppid, agoraChannel, agoraUid, agoraCameraToken, agoraScreenToken } = state
      let screenClient = null
      let videoClient = null
      
      let screenUid = Number('1' + agoraUid)
      let videoUid = Number('2' + agoraUid)
      
      // 屏幕分享
      async function startScreenCall() {
        // 创建视频
        let screenTrack = await AgoraRTC.createScreenVideoTrack({
          optimizationMode: "motion"
          // motion流畅 detail清晰
          // 如果该参数留空，则使用 SDK 默认的优化模式：
          //  对于屏幕共享视频轨道，SDK 默认的优化策略为清晰优先。
          //  对于其他两种类型的本地视频轨道，SDK 默认的优化策略为兼顾清晰和流畅，也就是说弱网条件下，帧率和分辨率都会被调整。
        });
        // 创建agora实例
        screenClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        // 保存实例
        commit('SET_SCREENCLIENT', screenClient)
        // 创建用户链接
        await screenClient.join(agoraAppid, agoraChannel, agoraScreenToken, screenUid);
        // 监听视频分享停止
        screenTrack.on("track-ended", function(){
          dispatch('stopScreenShare')
        })
        commit('SET_SCREENTRACK', screenTrack)
        // 加入订阅
        if (examinationRoomType != 3) {
          await screenClient.publish(screenTrack);
        }
      }

      // 视频直播
      async function startVideoCall() {
        // 创建视频轨道
        let videoTrack = await AgoraRTC.createCameraVideoTrack({
          optimizationMode: "motion"
          // motion流畅 detail清晰
          // 如果该参数留空，则使用 SDK 默认的优化模式：
          //  对于屏幕共享视频轨道，SDK 默认的优化策略为清晰优先。
          //  对于其他两种类型的本地视频轨道，SDK 默认的优化策略为兼顾清晰和流畅，也就是说弱网条件下，帧率和分辨率都会被调整。
        });
        // 创建音频轨道
        let audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // 创建agora实例
        videoClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        // 保存实例
        commit('SET_VIDEOCLIENT', videoClient)
        // 创建用户链接
        await videoClient.join(agoraAppid, agoraChannel, agoraCameraToken, videoUid);
        // 加入订阅
        if (examinationRoomType != 3) {
          await videoClient.publish([videoTrack, audioTrack]);
        }
      }
      
      Promise.all([startScreenCall(), startVideoCall()]).then(() => {
        commit('SET_SHARETYPE', 1)
        // // 开启云端录制
        // agroaRecordingStart({
        //   channel_name: agoraChannel,
        //   uid1: screenUid,
        //   uid2: videoUid,
        //   token1: agoraScreenToken,
        //   token2: agoraCameraToken,
        // }).then(res => {
        //   commit('SET_AGORARESOURCEID', res.data.resourceId)
        //   commit('SET_AGORASID', res.data.sid)
        // })
      }).catch(() => {
        // 如果取消分享 两个都关闭链接
        if (state.screenClient) state.screenClient.leave();
        if (state.videoClient) state.videoClient.leave();
      }).finally(() => {
        commit('SET_SHARESTATUS', false)
      })
    },

    // 停止分享
    async stopScreenShare({ commit }) {
      commit('SET_SHARESTATUS', true)
      await state.screenClient.leave();
      await state.videoClient.leave();
      commit('SET_SHARESTATUS', false)
      commit('SET_SHARETYPE', 2)

      // const { agoraChannel, agoraResourceId, agoraSid, agoraUid } = state
      
      // let screenUid = Number('1' + agoraUid)
      // let videoUid = Number('2' + agoraUid)
      // // 关闭云端录制
      // agroaRecordingStop({
      //   channel_name: agoraChannel,
      //   resource_id: agoraResourceId,
      //   sid: agoraSid,
      //   uid1: screenUid,
      //   uid2: videoUid,
      // }).then(res => {
        
      // })
    }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}