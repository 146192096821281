import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/style/reset.css";
import "@/assets/style/index.css";
// import "@/utils/rem.js";
import "./permission";
import animated from 'animate.css'

Vue.use(animated)
Vue.use(ElementUI);
Vue.config.productionTip = false;

// 全局表頭組件
import NavHeader from "@/components/NavHeader"
import NavFooter from "@/components/NavFooter"
Vue.component('NavHeader', NavHeader)
Vue.component('NavFooter', NavFooter)

// 全局图片路径
let imgUrl = ''
if (process.env.NODE_ENV === 'development') {
  imgUrl = process.env.VUE_APP_BASEURL
} else {
  imgUrl = window.location.protocol + "//" + window.location.host
}
Vue.prototype.$imgUrl = imgUrl

new Vue({
  router,
  store,
  render: (h) => h(App),      
}).$mount("#app");
