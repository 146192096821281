<template>
  <div id="app" class="notranslate">
    <div v-if="flag" @contextmenu="handleMouse">
      <router-view />
    </div>
    <div v-if="!flag" @contextmenu="handleMouse" @paste="preventPaste" @paste.prevent>
      <router-view />
    </div>

    <!-- 考試時間結束彈窗 -->
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="app-popup" v-show="examOverShow">
        <transition
          enter-active-class="animated zoomIn"
          leave-active-class="animated zoomOut"
        >
          <div class="timeOver-popup" v-if="examOverShow">
            <h1 class="timeOver-popup-h1">考試時間結束</h1>
            <div class="timeOver-popup-line"></div>
            <img class="timeOver-popup-img" src="@/assets/image/examination/deliver3.png" alt="">
            <h2 class="timeOver-popup-h2">別擔心！你的答案我們都收到了！</h2>
            <p class="timeOver-popup-p">畫面將在{{ examCountDown }}秒後自動關閉</p>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: 'app',
  data() {
    return {
      flag: false,

      // 考試時間結束-彈窗狀態
      examOverShow: false,
      // 考試時間結束-彈窗倒計時 實際時間+1
      examCountDown: 4,
    }
  },
  computed: {
    ...mapGetters(['examOverStatus']),
  },
  watch: {
    examOverStatus(val) {
      if (val) {
        this.examCountDown = 4
        this.countTime()
      }
      this.examOverShow = val
    },
    '$route': {
      handler(to, from){
        if (to.path == '/login') {
          this.flag = true
        } else {
          this.flag = false
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  methods: {
    // 倒計時 结束并关闭弹窗
    countTime() {
      if (this.examCountDown > 0) {
        this.examCountDown -= 1
        setTimeout(this.countTime, 1000)
      } else {
        // 关闭弹窗
        this.$store.commit('exam/SET_EXAMOVERSTATUS', false)
        this.$router.push({
          path: '/period',
          replace: true
        })
      }
    },
    // 禁用鼠標右鍵
    handleMouse(e) {
      e.preventDefault();
    },
    // 禁用粘貼
    preventPaste() {
      // console.log('禁止粘貼')
    }
  }
  // mounted() {
  //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  // methods: {
  //   // 这个事件只有在鼠标真正和浏览器有了交互，再刷新或者关闭时才会触发, 浏览器事件会弹框确认用户是否要离开页面
  //   beforeunloadHandler(e) {
  //     console.log('頁面刷新時 在此處做處理')
  //     // 这个事件只有在鼠标真正和浏览器有了交互，再刷新或者关闭时才会触发, 浏览器事件会弹框确认用户是否要离开页面
  //     e = e || window.event
  //     if (e) {
  //       e.returnValue = '关闭提示'
  //     }
  //     return '关闭提示'
  //   }
  // },
  // destroyed() {
  //   window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // } 
}
</script>
<style lang="less" scoped>
#app {
  background: #F5F7FD;
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
  
  // 考試時間結束彈窗
  .timeOver-popup {
    padding: 40px 5px;
    width: 570px;
    background: #FFFFFF;
    border-radius: 12px;
    text-align: center;
    .timeOver-popup-h1 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      color: #333333;
    }
    .timeOver-popup-line {
      margin: 24px auto;
      width: 100%;
      height: 1px;
      background: #EAEAEA;
    }
    .timeOver-popup-img {
      width: 367px;
      height: 250px;
    }
    .timeOver-popup-h2 {
      margin: 30px 0 20px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 35px;
      text-align: center;
      color: #333333;
    }
    .timeOver-popup-p {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #989898;
    }
  }
}
</style>