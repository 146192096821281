import { questionLoadExam } from "@/request/api"
import store from '@/store'
const state = {
  // 考場狀態
  examOverStatus: false,
  // 考试倒计时
  examCountdown: '00:00',
  // 当前进行的考试类型 0未开始  1听力 2阅读 3写作 4口说
  examType: 0,
  // 考试进行状态
  examStatus: 0,
  // 题目序号
  examAuestionSort: 0,
  // 考试剩余时间 秒
  examRemainingTime: 0,
  // 考试休息时间状态
  examRestTimeStatus: false,
  // 考试答题 是否进入至最后一题
  examIsLast: 0,
  // 考場類型 1正式 2仿真模擬 3練習模擬
  examinationRoomType: sessionStorage.getItem('examinationRoomType'),
}

const mutations = {
  SET_EXAMCOUNTDOWN(store, examCountdown) {
    // 有可能没获取到，为NaN
    if (!examCountdown) {
      store.examCountdown = "00:00"
    } else {
      let min = Math.floor(examCountdown / 60).toString().padStart(2, '0')
      let sec = Math.floor(examCountdown % 60).toString().padStart(2, '0')
      store.examCountdown = `${min}:${sec}`
    }
  },
  // 考場關閉狀態 
  SET_EXAMOVERSTATUS(store, examOverStatus) {
    store.examOverStatus = examOverStatus
  },
  // 当前进行的考试类型 
  SET_EXAMTYPE(store, examType) {
    store.examType = examType
  },
  // 考试进行状态 
  SET_EXAMSTATUS(store, examStatus) {
    store.examStatus = examStatus
  },
  // 题目序号 
  SET_EXAMAUESTIONSORT(store, examAuestionSort) {
    store.examAuestionSort = examAuestionSort
  },
  // 考试剩余时间 
  SET_EXAMREMAININGTIME(store, examRemainingTime) {
    store.examRemainingTime = examRemainingTime
  },
  // 考试休息时间状态 
  SET_EXAMRESTTIMESTATUS(store, examRestTimeStatus) {
    store.examRestTimeStatus = examRestTimeStatus
  },
  // 考试答题 是否进入至最后一题
  SET_EXAMISLAST(store, examIsLast) {
    store.examIsLast = examIsLast
  },
  // 考場類型
  SET_EXAMINATIONROOMTYPE(store, examinationRoomType) {
    store.examinationRoomType = examinationRoomType
  },
}

const actions = {
  // 獲取考試狀態
  getLoadExam({ commit }) {
    return new Promise(resolve => {
      questionLoadExam().then(res => {
        commit('SET_EXAMTYPE', res.data.type)
        commit('SET_EXAMSTATUS', res.data.status)
        commit('SET_EXAMAUESTIONSORT', res.data.question_sort)
        commit('SET_EXAMCOUNTDOWN', res.data.reciprocalTime)
        commit('SET_EXAMREMAININGTIME', res.data.remaining_time)
        commit('SET_EXAMISLAST', res.data.is_last)
        
        // 设置agora的token
        store.commit('agora/SET_AGORACAMERATOKEN', res.data.camera_token)
        store.commit('agora/SET_AGORASCREENTOKEN', res.data.screen_sharing_token)
        resolve(res.data)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
