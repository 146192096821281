const state = {
  // 准考證號碼
  admissionTicket: sessionStorage.getItem('exam_id'),
  // 報考級數
  entryLevel: sessionStorage.getItem('exam_level')
}

const mutations = {
  SET_ADMISSIONTICKET(store, admissionTicket) {
    store.admissionTicket = admissionTicket
  },
  SET_ENTRYLEVEL(store, entryLevel) {
    store.entryLevel = entryLevel
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}