import Vue from "vue";
import Vuex from "vuex";
import getters from './getters'
import agora from './modules/agora'
import exam from './modules/exam'
import problem from './modules/problem'
import user from './modules/user'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agora,
    exam,
    problem,
    user
  },
  getters
});
