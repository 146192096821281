const state = {
  // 問題回報狀態  0默認不顯示  1取消回報  2問題已收到
  problemStatus: 0,
  // 問題回報问题状态 1已完成處理  2無法處理問題，但已註記問題狀況  3無法成功聯繫學生
  problemResultsStatus: 1,
  // 問題回報彈窗狀態
  problemPopupStatus: false,
  // 問題回報回顯的回答
  problemResults: '',
}

const mutations = {
  SET_PROBLEMSTATUS(store, problemStatus) {
    store.problemStatus = problemStatus
  },
  SET_PROBLEMRESULTSSTATUS(store, problemResultsStatus) {
    store.problemResultsStatus = problemResultsStatus
  },
  SET_PROBLEMRESULTS(store, problemResults) {
    store.problemResults = problemResults
  },
  SET_PROBLEMPOPUPSTATUS(store, problemPopupStatus) {
    store.problemPopupStatus = problemPopupStatus
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
