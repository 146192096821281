import { post } from '@/request'

// 用户-登录
export const userLogin = p => post('/api/login', p)

// 文件上传
export const fileUpload = p => post('/api/upload', p)

// 首页-考场信息
export const examinationRoomInfo = p => post('/api/question/get_examination_room', p)

// 首页-开始考试
export const examinationStartTest = p => post('/api/question/start_test', p)

// 硬体检测-硬体检测完成开始考试
export const examinationStartTestAgain = p => post('/api/question/start_test_again', p)
// 硬体检测-问题图片列表
export const questionHardwareProblem = p => post('/api/question/hardware_problem', p)

// 考试信息-读档
export const questionLoadExam = p => post('/api/question/load_exam', p)
// 考试信息-存檔
export const questionSaveExam = p => post('/api/question/save_exam', p)

// 考试-题目列表
export const questionList = p => post('/api/question/question', p)
// 考试-开始测验
export const questionBegins = p => post('/api/question/quiz_begins', p)
// 考试-答题
export const questionAnswer = p => post('/api/question/answer_questions', p)
// 考试-交卷
export const questionFinish = p => post('/api/question/finish_up_job', p)
// 考试-准考证号码录制提交
export const questionExamIdEntering = p => post('/api/question/exam_id_entering', p)

// 问题回报-问题类型列表
export const questionFaqsList = p => post('/api/question/faqs_list', p)
// 问题回报-提交问题
export const questionProblemReturn = p => post('/api/question/problem_return', p)
// 问题回报-取消問題回報
export const questionCancelProblemReturn = p => post('/api/question/cancel_problem_return', p)
// 问题回报-獲取問題回報處理狀態
export const questionGetProblemStatus = p => post('/api/question/get_problem_status', p)


// agora-开启云端录制
export const agroaRecordingStart = p => post('/api/question/recording_start', p)
// agora-停止云端录制
export const agroaRecordingStop = p => post('/api/question/recording_stop', p)