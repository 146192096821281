import router from "./router";
import store from './store'

// 路由守卫
router.beforeEach(async(to, form, next) => {
  // 设置页面title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'JEPT' //此处写默认的title
  }
  // 设置api白名单
  const whiteList = ['/home', '/login', '/period']
  // 白名单的不做验证
  if (whiteList.includes(to.path)) {
    next()
  } else {
    // 根据登录状态判断是否进入页面
    let token = window.sessionStorage.getItem('token')
    if (!token) {
      next("/home")
    } else {
      if (to.path == '/halfTime' && store.getters.examRestTimeStatus) {
        return next()
      }
      // 验证当前读档状态
      const data = await store.dispatch('exam/getLoadExam')
      
      // 考試等級判斷
      // 1-4級只有聽力測驗 閱讀測驗
      // 5-6級只有聽力測驗 閱讀測驗 寫作測驗
      // 7-8級只有聽力測驗 閱讀測驗 寫作測驗 口說測驗
      // 获取答题状态  跳转到对应的页面
      // type -1硬体检测完成  0开始考试  1听力测验  2阅读测验  3写作测验  4口说测验
      // status 1进行中  2已完成
      // type=0 考试已开始 硬体检测未完成
      // type=-1 硬体检测已完成 并听力测验未开始
      // type=1 status=1 听力测验进行中
      // type=1 status=2 听力测验已结束 并阅读测验未开始
      // type=2 status=1 阅读测验进行中
      // type=2 status=2 阅读测验已结束 并写作测验未开始
      // type=3 status=1 写作测验进行中
      // type=3 status=2 写作测验已结束 并口说测验未开始
      // type=4 status=1 口说测验进行中
      // type=4 status=2 口说测验已结束 并考试已结束开始
      if (data.type == 1) { // 听力部分
        if (data.status == 1) { // 听力部分进行中
          if (to.path != '/hearingExam') {
            next("/hearingExam")
          } else {
            next()
          }
        } else { // 听力部分已交卷 跳转到阅读部分
          if (to.path != '/readExam') {
            next("/readExam")
          } else {
            next()
          }
        }
      } else if (data.type == 2) { // 阅读部分
        if (data.status == 1) { // 阅读部分进行中
          if (to.path != '/readExam') {
            next("/readExam")
          } else {
            next()
          }
        } else { // 阅读部分已交卷 跳转到写作部分
          // 閱讀測驗完成 1-4級跳考試結束 5-8級跳寫作頁面
          if ([1, 2, 3, 4].includes(Number(store.getters.entryLevel))) {
            if (to.path != '/examinationOver') {
              next("/examinationOver")
            } else {
              next()
            }
          } else {
            if (to.path != '/writingExam') {
              next("/writingExam")
            } else {
              next()
            }
          }
        }
      } else if (data.type == 3) { // 写作部分
        if (data.status == 1) { // 写作部分进行中
          if (to.path != '/writingExam') {
            next("/writingExam")
          } else {
            next()
          }
        } else { // 写作部分已交卷 跳转到口说部分
          // 寫作測驗完成 1-6級跳考試結束 7-8級跳口說頁面
          if ([1, 2, 3, 4, 5, 6].includes(Number(store.getters.entryLevel))) {
            if (to.path != '/examinationOver') {
              next("/examinationOver")
            } else {
              next()
            }
          } else {
            if (to.path != '/reciteExam') {
              next("/reciteExam")
            } else {
              next()
            }
          }
        }
      } else if (data.type == 4) { // 口说部分
        if (data.status == 1) { // 口说部分进行中
          if (to.path != '/reciteExam') {
            next("/reciteExam")
          } else {
            next()
          }
        } else { // 口说部分已交卷 跳转到考试已结束页面
          if (to.path != '/examinationOver') {
            next("/examinationOver")
          } else {
            next()
          }
        }
      } else if (data.type == -1) { // 硬体检测完成 跳转至听力测验未开始状态
        if (to.path != '/hearingExam') {
          next("/hearingExam")
        } else {
          next()
        }
      } else { // 未开始状态 正常走流程
        next()
      }
    }
  }
})